export const messageCodeHTTP = Object.freeze({
  200: 'Petición exitosa',
  201: '',
  202: '',
  204: '',
  400: '',
  401: 'Sin autorización',
  403: 'No tiene permisos para realizar está acción',
  404: 'Not Found',
  406: '',
  410: '',
  422: '',
  500: 'Error de servidor 501',
  502: 'Error de servidor 502',
  503: 'Error de servidor 503',
  504: 'Error de servidor 504'
});

export const caseTypes = Object.freeze({
  VEHICULOS: 1,
  DIVERSOS: 2,
  TRASLADOS: 3
});

export const transfersStatusEnum = Object.freeze({
  POR_VALIDAR: 1,
  EN_TRANSITO: 2,
  INCIDENCIA: 3,
  RECOLECTADO: 4,
  ENTREGADO: 5,
  CANCELADO: 6
});

export const actionsIdEnum = Object.freeze({
  POR_VALIDAR: 1,
  EN_TRANSITO: 2,
  INCIDENCIA: 3,
  RECOLECTADO: 4,
  ENTREGADO: 5,
  CANCELADO: 6
});

export const servicesTypeENUM = Object.freeze({
  GRUA: 1,
  CORRALON: 2,
  GESTORIA: 3,
  VEHICULOS: 4,
  DIVERSOS: 5
});

export const insuredInputsPage = Object.freeze({
  VALIDATE: 'validate',
  UPDATE: 'update',
  REGISTER: 'register',
  AUTO_REGISTER: 'autoRegister',
  DETAIL: 'detail'
});

export const documentsTypeEnum = Object.freeze({
  FACTURA_DE_ORIGEN: 20,
  FACTURAS_INTERMEDIAS: 21,
  ULTIMA_FACTURA: 22,
  TENENCIA : 23,
  BAJA_DE_PLACAS : 24,
  RECIBO_BAJA_DE_PLACAS : 25,
  LLAVES : 26,
  ACTA_DE_ROBO : 27,
  LIBERACION : 28,
  ESTATUS_REPORTE_REPUVE : 29,
  OTROS : 30,
});

export const transfersGalleryTabsEnum = Object.freeze({
  FOTOGRAFIAS_DEL_CASO: '1',
  FOTOGRAFIAS_DE_RECOLECCION: '2',
  FOTOGRAFIAS_DE_ENTREGA: '3',
  FOTOGRAFIAS_DE_AUDATEX: '4',
  FOTOGRAFIAS_DE_ENTRADA_INVENTARIO: '5',
  FOTOGRAFIAS_DE_SALIDA_DE_INVENTARIO: '6',
  ALL: 'ALL'
});

export const productsStatusEnum = Object.freeze({
  EN_PROCESO: 1,
  PRE_PUBLICADO: 4,
  PUBLICADO: 5,
  POR_ADJUDICAR: 6,
  ADJUDICADO: 7,
  EN_PROCESO_DE_PAGO: 8,
  PAGADO: 9,
  ENTREGADO: 10,
  CERRDADO: 11,
  POR_VALIDAR_ADJUDICACION: 12
});

export const casesStatusEnum = Object.freeze({
  EN_PROCESO: 1,
  EN_VALIDACION_DE_DOCUMENTOS: 2,
  DOCUMENTADO: 3,
  PRE_PUBLICADO: 4,
  PUBLICADO: 5,
  POR_ADJUDICAR: 6,
  ADJUDICADO: 7,
  EN_PROCESO_DE_PAGO: 8,
  PAGADO: 9,
  ENTREGADO: 10,
  CERRDADO: 11,
  POR_VALIDAR_ADJUDICACION: 12
});

export const advertsStatusEnum = Object.freeze({
  ACTIVA: 2,
  CANCELADA: 4,
  CERRADA: 5,
  EN_PROCESO: 1,
  INACTIVA: 3
});

export const adverTypesEnum = Object.freeze({
  VEHICULOS: 1,
  DIVERSOS: 2
});

export const adverTypesNamesEnum = Object.freeze({
  1: 'Vehículos',
  2: 'Diversos'
});

export const advertsStatusName = Object.freeze({
  1: 'En proceso',
  2: 'Activa',
  3: 'Inactiva',
  4: 'Cancelada',
  5: 'Cerrada'
});

export const viewTypes = Object.freeze({
  INITIAL: 0,
  POR_PUBLICACION: 1,
  UNITARIA: 2
});

export const bankInfoEnum = Object.freeze({
  CUENTA: 1,
  CLABE: 2,
  CONVENIO_CIE: 3,
  BANCO: 4,
  COSTOADMINISTRATIVO: 5,
  COSTOMERCADO: 6,
  PORCENTAJE: 7,
  LIMITE: 8,
  MONTO: 9
});

export const awardingsEnum = Object.freeze({
  APROBADA: 2,
  RECHAZADA: 3
});

export const semaphore = Object.freeze({
  GREEN: 1,
  YELLOW: 2,
  RED: 3
});

export const paymentStatusEnum = Object.freeze({
  PENDIENTE_POR_ADJUNTAR: 1,
  POR_VALIDAR: 2,
  RECHAZADO: 3,
  APRODBADO: 4,
  CANCELADO: 5
});

export const fileStatusEnum = Object.freeze({
  LOADED: 0,
  ATTACHED: 1,
  APPROVED: 2,
  REJECTED: 3,
  FAVORITE: 4,
  ON_VALIDATION: 5
});

export const casesStatusLabelEnum = Object.freeze({
  1: 'En proceso',
  2: 'En validación de documentos',
  3: 'Documentado',
  4: 'Pre publicado',
  5: 'Publicado',
  6: 'Por adjudicar',
  7: 'Adjudicado',
  8: 'En proceso de pago',
  9: 'Pagado',
  10: 'Entregado',
  11: 'Cerrado',
  12: 'Por validar adjudicación'
});

export const fileStatusLabelEnum = Object.freeze({
  0: 'Cargado',
  1: '',
  2: 'Aprobado',
  3: 'Rechazado',
  4: 'Favorito',
  5: 'En validación'
});

export const locationTypeEnum = Object.freeze({
  WORKSHOP: 1,
  CAR_POUND: 2,
  OTHER: 3
});

export const compensationStatusEnum = Object.freeze({
  ON_VALIDATION: 1,
  REVIEWING: 2,
  CALCULATED: 3
});

export const usersRoles = Object.freeze({
  ADMIN_MASTER: 'AdministradorMaster',
  ADMIN_INTERN: 'AdministradorInterno',
  INSURANCE_MASTER: 'AseguradoraMaster',
  INSURANCE_INTERN: 'AseguradoraInterno',
  PROVIDER_CRANE: 'ProveedorGrúa',
  PERSONAL_BUYER: 'CompradorComprador (Persona Física)',
  MORAL_BUYER: 'CompradorComprador (Persona Moral)'
});

export const userStatus = Object.freeze({
  ACTIVE: 1,
  INACTIVE: 0
});

export const rolePublicName = Object.freeze({
  MASTER: 'Master',
  INTERNO: 'Interno',
  ROOT: 'ROOT',
  INTEGRATION: 'INTEGRATION'
});

export const groupRole = Object.freeze({
  ADMINISTRADOR: 'Administrador',
  ASEGURADORA: 'Aseguradora',
  ASEGURADO: 'Asegurado',
  PROVEEDOR: 'Proveedor',
  COMPRADOR: 'Comprador',
  IT: 'IT',

});

export const inventoryStatus = Object.freeze({
  CHECKIN: 1,
  CHECKOUT: 2
});

export const userRolesIds = Object.freeze({
  ADMIN_MASTER: 'ff08ef45-f8d9-4f6e-bb57-c1a5785794c9',
  ADMIN_INTERN: 'c2ed80ff-57df-4afc-a363-64e181f7ba11',
  INSURANCE_MASTER: 'a4cd7c22-56f5-4549-8582-be6a6f0e82c1',
  INSURANCE_INTERN: '0843d646-f6e4-4243-8390-1d6e7e9f4e48',
  PROVIDER_CRANE: '742315ef-c899-4648-91eb-e30e4fe7bf4f',
  PERSONAL_BUYER: '4f9be6de-9b14-4729-ae01-8739310255be',
  MORAL_BUYER: '58497941-59d2-430e-b92a-777ead815e5e',
  ROOT: 'a4af5f78-8ae5-44be-ba5c-f0f7c8da59b7',
  INTEGRATION: '8e6f0957-1c30-4d71-b64d-a75b7a3826d4',
  CARPOUND_PROVIDER: 'd45a5dc8-312e-480d-9ffe-fde572328966'
});

export const dateType = [
  { id: 1, name: 'Asignación de Traslados' },
  { id: 2, name: 'Inicio de Traslados' },
  { id: 3, name: 'Incidencia de Traslados' },
  { id: 4, name: 'Recoleción de Traslados' },
  { id: 5, name: 'Entrega de Traslados' },
  { id: 6, name: 'Cancelación de Traslados' }
];

export const monthType = [
  { id: 1, name: 'Mes actual' },
  { id: 2, name: 'Mes anterior' },
  { id: 3, name: 'Últimos 3 meses' }
];

export const paymentReferenceEnum = Object.freeze({
  AWARD: 1,
  ADMINISTRATIVE : 2,
  INSURANCE: 3
});

export const insuranceTypeEnum = Object.freeze({
  VEHICLES : 4, 
  DIVERSE : 5,
});

export const awardStatus = Object.freeze({
  EN_VALIDACION : 1,
  APROBADO: 2,
  RECHAZDO : 3,
  SIN_EVALUACION: 4,
  CANCELADA: 5
});

export const notificationType = Object.freeze({
  ADVERT_CLOSED: 1,
  AWARDING: 2,
  AWARDING_CANCELED: 3,
  PAYMENTS_VALIDATION: 4,
  AWARDING_UPDATE: 5,
  ON_AWARD_REVIEW: 6,
  REPORT_READY: 7
});

export const attachmentType = Object.freeze({
  USER: 0,
  DOCUMENT: 1,
  IMAGE: 2
});

export const reportTypeButtonEnum = Object.freeze({
  BIDS: 0,
  RESULTS: 1,
  RESULTS_BIDS: 2,
  ADVERTS: 3
});

export const reportTypeEnum = Object.freeze({
  PREPUBLISH: 1,
  DETAIL: 2,
  RESULTS: 3,
  BIDS: 4,
  BUYER_ADVERTS: 5,
  BUYER_AWARDINGS: 6,
  CASES: 7,
  ADVERTS: 8,
  PAYMENTS: 9,
  VIEWS: 10,
  TRANSFERS: 11,
  INVENTORY_INCOMMING: 12,
  INVENTORY_ENTRY: 13,
  INVENTORY_EXIT: 14,
  DOCUMENTS: 15,
});


export const paymentReferenceStatusIds = Object.freeze({
  PENDINGTOATTACH : 1,
  TOVALIDATE : 2,
  REJECTED : 3,
  APPROVED : 4,
  CANCELED : 5
});

export const PicsZipTypeEnum = Object.freeze({
  ALL: 0,
  CASE: 1,
  COLLECTION: 2,
  DELIVERY: 3,
  INVENTORYIN: 4,
  INVENTORYOUT: 5 
});

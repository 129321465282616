import {
  CREATE_ONE_CASE_REQUEST,
  CREATE_ONE_CASE_SUCCESS,
  CREATE_ONE_CASE_FAIL,
  CREATE_ONE_TRANSFER_CASE_REQUEST,
  CREATE_ONE_TRANSFER_CASE_SUCCESS,
  CREATE_ONE_TRANSFER_CASE_FAIL
} from '../actions/index';

function oneCase(state = {}, action) {
  const { type } = action;

  switch (type) {
    case CREATE_ONE_CASE_REQUEST:
    case CREATE_ONE_TRANSFER_CASE_REQUEST:
      return {
        ...state,
        isPosting: true
      };

    case CREATE_ONE_CASE_SUCCESS:
    case CREATE_ONE_TRANSFER_CASE_SUCCESS:
      return {
        ...state,
        isPosting: false
      };
    case CREATE_ONE_TRANSFER_CASE_FAIL:
    case CREATE_ONE_CASE_FAIL:
      return {
        ...state,
        isPosting: false
      };

    default:
      return state;
  }
}

export default oneCase;

export const CREATE_ONE_CASE_REQUEST = 'CREATE_ONE_CASE_REQUEST';
export const CREATE_ONE_CASE_SUCCESS = 'CREATE_ONE_CASE_SUCCESS';
export const CREATE_ONE_CASE_FAIL = 'CREATE_ONE_CASE_FAIL';

export const CREATE_ONE_TRANSFER_CASE_REQUEST = 'CREATE_ONE_TRANSFER_CASE_REQUEST';
export const CREATE_ONE_TRANSFER_CASE_SUCCESS = 'CREATE_ONE_TRANSFER_CASE_SUCCESS';
export const CREATE_ONE_TRANSFER_CASE_FAIL = 'CREATE_ONE_TRANSFER_CASE_FAIL';

export const UPLOAD_CASE_PHOTOS = 'UPLOAD_CASE_PHOTOS';
export const UPLOAD_CASE_PHOTO = 'UPLOAD_CASE_PHOTO';
export const UPLOAD_CASE_PHOTOS_FAIL = 'UPLOAD_CASE_PHOTOS_FAIL';
export const UPLOAD_CASE_PHOTO_FAIL = 'UPLOAD_CASE_PHOTO_FAIL';
export const UPLOAD_CASE_PHOTO_SUCCESS = 'UPLOAD_CASE_PHOTO_SUCCESS';

export function uploadCasePhotoSuccess() {
  return {
    type: UPLOAD_CASE_PHOTO_SUCCESS
  };
}

export function uploadCasePhotoFail() {
  return {
    type: UPLOAD_CASE_PHOTO_FAIL
  };
}

export function uploadCasePhotosFail() {
  return {
    type: UPLOAD_CASE_PHOTOS_FAIL
  };
}

export function uploadCasePhoto(payload) {
  return {
    type: UPLOAD_CASE_PHOTO,
    payload: payload
  };
}

export function uploadCasePhotos(payload) {
  return {
    type: UPLOAD_CASE_PHOTOS,
    payload: payload
  };
}

export function createOneCaseRequest(payload) {
  return {
    type: CREATE_ONE_CASE_REQUEST,
    payload: payload
  };
}

export function createOneCaseSuccess(payload) {
  return {
    type: CREATE_ONE_CASE_SUCCESS,
    payload: payload
  };
}

export function createOneCaseFail(payload) {
  return {
    type: CREATE_ONE_CASE_FAIL,
    payload: payload
  };
}

export function createTransferCaseRequest(payload) {
  return {
    type: CREATE_ONE_TRANSFER_CASE_REQUEST,
    payload: payload
  };
}

export function createTransferCaseSuccess(payload) {
  return {
    type: CREATE_ONE_TRANSFER_CASE_SUCCESS,
    payload: payload
  };
}

export function createTransferCaseFail(payload) {
  return {
    type: CREATE_ONE_TRANSFER_CASE_FAIL,
    payload
  };
}

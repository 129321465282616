import { takeEvery, put, call, all } from 'redux-saga/effects';
import {
  CREATE_ONE_CASE_REQUEST,
  createOneCaseSuccess,
  createOneCaseFail,
  CREATE_ONE_TRANSFER_CASE_REQUEST,
  createTransferCaseSuccess,
  createTransferCaseFail,
  uploadCasePhotos,
  UPLOAD_CASE_PHOTOS,
  uploadCasePhoto,
  UPLOAD_CASE_PHOTO,
  uploadCasePhotosFail,
  uploadCasePhotoFail,
  uploadCasePhotoSuccess
} from '../../actions';
import { postOneCase, postTransferCase, postUploadPhotos } from '../../../services';
import { assingTransfer } from 'services/transfers';
import { sendNotification } from 'helpers/helperNotifications';
import { caseTypes, locationTypeEnum } from 'models/enums';

export function* workCreateCaseRequest(action) {
  try {
    const { payload } = action;
    let picsToCase = [];
    const { requestData, onSuccess, caseType } = payload;
    let data = {};
    let caseTypeName;
    if (caseType === caseTypes.VEHICULOS) {
      caseTypeName = 'VehicleCase';
      let location;
      switch (requestData.locationInfo?.locationTypeId) {
        case locationTypeEnum.OTHER:
          location = {
            destinationName: requestData.locationInfo.destinationName
          };
          break;
        case locationTypeEnum.CAR_POUND:
          location = {
            branchId: requestData.locationInfo.branchId
          };
          break;
        case locationTypeEnum.WORKSHOP:
          location = {
            workshopNumber: requestData.locationInfo.workshopNumber,
            workshopName: requestData.locationInfo.workshopName
          };
          break;
        default:
          location = {};
          break;
      }

      location = {
        ...location,
        locationTypeId: requestData.locationInfo?.locationTypeId,
        address: {
          ...requestData.locationInfo
        }
      };

      picsToCase = requestData.pics;
      data = {
        vehicleInfo: requestData.vehicleInfo,
        locationInfo: location,
        wreckVehicleInfo: requestData.wreckVehicleInfo,
        picsUploaded: picsToCase.length,
        VideosUrl : requestData.VideosUrl
      };
    } else if (caseType === caseTypes.DIVERSOS) {
      const { pics, ...request } = requestData;
      caseTypeName = 'ProductCase';
      picsToCase = pics;
      data = { ...request, picsUploaded: picsToCase.length, VideosUrl : requestData.VideosUrl };
    }

    const response = yield call(postOneCase, { data, caseTypeName });
    //console.log('response :>> ', response);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(uploadCasePhotos({ picsToCase, caseId: response.data.data }));
      sendNotification('success', response.data.message);
      yield onSuccess();
      yield put(createOneCaseSuccess(response.data.data));
    } else {
      yield put(createOneCaseFail());
    }
  } catch (_error) {
    //  console.error('[Redux-Saga-Error-AutoOnline]', error);
    yield put(createOneCaseFail());
  }
}

export function* workCreateTransferRequest(action) {
  try {
    const { payload } = action;

    const { requestData, onSuccess } = payload;

    // Pics
    const pics = requestData?.pics.map(pic => {
      return { content: pic.content, asFavorite: pic.asFavorite };
    });

    // Checks buyerId
    let buyers;
    if (requestData?.wreckVehicleInfo?.buyers === 'other') {
      buyers = {
        buyerId: null,
        requestantName: requestData?.wreckVehicleInfo?.name
      };
    } else {
      buyers = {
        buyerId: requestData?.wreckVehicleInfo?.buyers,
        requestantName: null
      };
    }

    let location;
    switch (requestData.locationInfo?.locationTypeId) {
      case locationTypeEnum.OTHER:
        location = {
          destinationName: requestData.locationInfo.destinationName
        };
        break;
      case locationTypeEnum.CAR_POUND:
        location = {
          branchId: requestData.locationInfo.branchId
        };
        break;
      case locationTypeEnum.WORKSHOP:
        location = {
          workshopNumber: requestData.locationInfo.workshopNumber,
          workshopName: requestData.locationInfo.workshopName
        };
        break;
      default:
        location = {};
        break;
    }

    let data = {
      picsUploaded: pics.length,
      caseId: null,
      vehicleInfo: {
        caseNumber: requestData?.vehicleInfo?.caseNumberTransfer,
        report: requestData?.vehicleInfo?.reportTransfer,
        insurancePolicy: requestData?.vehicleInfo?.insurancePolicyTransfer,
        wreckTypeId: requestData?.vehicleInfo?.wreckTypeIdTransfer,
        wreckSubtypeId: requestData?.vehicleInfo?.wreckSubtypeIdTransfer,
        brand: requestData?.vehicleInfo?.brandTransfer,
        type: requestData?.vehicleInfo?.typeTransfer,
        model: requestData?.vehicleInfo?.modelTransfer,
        color: requestData?.vehicleInfo?.colorTransfer,
        vehicleTypeId: requestData?.vehicleInfo?.vehicleTypeIdTransfer,
        policySerial: requestData?.vehicleInfo?.policySerialTransfer,
        vehicleSerial: requestData?.vehicleInfo?.vehicleSerialTransfer,
        vehiclePlate: requestData?.vehicleInfo?.vehiclePlateTransfer,
        engineNumber: requestData?.vehicleInfo?.engineNumberTransfer,
        otherWreckSubTypeId : requestData?.vehicleInfo?.OtherWreckSubTypeId,
        // wreckSubtypeOther: requestData?.vehicleInfo?.,
        engineTypeId: requestData?.vehicleInfo?.engineTypeIdTransfer,
        engineTypeOther: requestData?.vehicleInfo?.engineTypeOtherTransfer,
        vehicleTypeOther: requestData?.vehicleInfo?.vehicleTypeOtherTransfer,
        wreckTypeOther: requestData?.vehicleInfo?.wreckTypeOtherTransfer,
        mileage: requestData?.vehicleInfo?.mileage
      },
      wreckVehicleInfo: {
        insuranceCarrierId: requestData?.wreckVehicleInfo?.insuranceCarrier,
        observations: requestData?.wreckVehicleInfo?.observationsSinister,
        c1: requestData?.wreckVehicleInfo?.c1,
        c2: requestData?.wreckVehicleInfo?.c2,
        c3: requestData?.wreckVehicleInfo?.c3
      },
      locationInfo: {
        ...location,
        address: {
          id: null,
          roadName: requestData?.locationInfo?.roadName,
          outNumber: requestData?.locationInfo?.outNumber,
          inNumber: requestData?.locationInfo?.inNumber,
          neighborhood: requestData?.locationInfo?.neighborhood,
          town: requestData?.locationInfo?.town,
          city: requestData?.locationInfo?.city,
          stateId: requestData?.locationInfo?.stateId,
          countryId: requestData?.locationInfo?.countryId,
          zipCode: requestData?.locationInfo?.zipCode
        },
        locationTypeId: requestData?.locationInfo?.locationTypeId,
        observations: requestData.locationInfo?.observations
      },
      transferCaseInfo: {
        ...buyers
      },
      VideosUrl: requestData.VideosUrl
    };

    let destination;
    if (requestData?.destinationInfo?.isOtherSelected?.[0]) {
      destination = {
        otherPlace: requestData?.destinationInfo?.destinationName
      };
    } else {
      destination = {
        carPoundId: requestData?.destinationInfo?.branches
      };
    }

    let transferData = {
      craneBranchId: requestData?.destinationInfo?.craneBranchId,
      ...destination,
      address: {
        id: null,
        roadName: requestData?.destinationInfo?.roadName,
        outNumber: requestData?.destinationInfo?.outNumber,
        inNumber: requestData?.destinationInfo?.inNumber,
        neighborhood: requestData?.destinationInfo?.neighborhood,
        town: requestData?.destinationInfo?.town,
        city: requestData?.destinationInfo?.city,
        stateId: requestData?.destinationInfo?.stateId,
        countryId: requestData?.destinationInfo?.countryId,
        zipCode: requestData?.destinationInfo?.zipCode
      },
      attenderName: requestData?.destinationInfo?.responsible,
      distanceTypeId : requestData?.destinationInfo?.transferTypeId,
      cost: requestData?.destinationInfo?.number
    };

    const response = yield call(postTransferCase, { data });
    // const response = { status: 20, data: { message: 'Caso exitoso', data: {} } };
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(uploadCasePhotos({ picsToCase: pics, caseId: response.data.data }));
      const dataTransfer = { ...transferData, caseId: response.data.data };
      const responseTransfer = yield call(assingTransfer, dataTransfer);
      if (typeof responseTransfer !== 'undefined' && responseTransfer.status === 200) {
        sendNotification('success', responseTransfer.data.message);
        yield onSuccess();
        yield put(createTransferCaseSuccess(responseTransfer.data.data));
      } else {
        yield put(createTransferCaseFail('FAIL'));
      }
    } else {
      yield put(createTransferCaseFail('FAIL'));
    }
  } catch (_error) {
    yield put(createTransferCaseFail('FAIL'));
  }
}

export function* workUploadCasePhoto(action) {
  try {
    const { payload } = action;
    const { data } = payload;
    const response = yield call(postUploadPhotos, { data });
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(uploadCasePhotoSuccess());
    } else {
      yield put(uploadCasePhotoFail());
    }
  } catch (_error) {
    yield put(uploadCasePhotoFail());
  }
}

export function* workUploadCasePhotos(action) {
  try {
    const { payload } = action;
    const { picsToCase, caseId } = payload;

    yield all(
      picsToCase?.map((p, index) => {
        let data = {
          picNumber: index + 1,
          caseId,
          content: p.content,
          asFavorite: p?.asFavorite,
          isCover : p?.isCover
        };

        return put(uploadCasePhoto({ data }));
      })
    );
  } catch (_error) {
    yield put(uploadCasePhotosFail());
  }
}

export function* watchCreateOneCaseRequest() {
  // yield takeEvery(CREATE_ONE_CASE_REQUEST, workCreateCaseRequest);
  yield all([takeEvery(CREATE_ONE_CASE_REQUEST, workCreateCaseRequest)]);
}

export function* watchCreateTransferRequest() {
  yield all([takeEvery(CREATE_ONE_TRANSFER_CASE_REQUEST, workCreateTransferRequest)]);
}

export function* watchUploadCasePhotos() {
  yield takeEvery(UPLOAD_CASE_PHOTOS, workUploadCasePhotos);
}

export function* watchUploadCasePhoto() {
  yield takeEvery(UPLOAD_CASE_PHOTO, workUploadCasePhoto);
}

import request from 'services/request';
const serviceURL = 'Cases/Api/v1/Cases/';
const servicePhotosUrl = 'Files/Api/v1/Pics/CasePic';
const transfersServiceURL = 'Cases/Api/v1/Cases/TransferCase';

export async function postOneCase({ data, caseTypeName }) {
  return request(`${serviceURL}${caseTypeName}`, {
    method: 'POST',
    data
  });
}

export async function postTransferCase({ data }) {
  return request(`${transfersServiceURL}`, {
    method: 'POST',
    data
  });
}

export async function postUploadPhotos({ data }) {
  return request(`${servicePhotosUrl}`, {
    method: 'POST',
    data
  });
}
